<template>
  <!--translate-->
  <div>
    <div class="d-flex justify-center mt-4">
      <v-btn color="primary" outlined @click="dialog = true">
        {{ $t("riskAssessment.chooseRiskFromTemplate") }}
      </v-btn>
    </div>
    <!-- dialog: -->
    <div>
      <v-dialog v-model="dialog" max-width="800" persistent>
        <v-card>
          <v-card-title class="headline">
            Gefahren auswählen und hinzufügen
          </v-card-title>
          <v-card-text>
            <v-treeview
              v-model="selectedItems"
              selectable
              return-object
              :items="presetItems.risks"
              item-children="tasks"
              item-key="title"
              selected-color="primary"
            >
              <template v-slot:prepend="{ item }">
                {{ item.title }}
              </template>
            </v-treeview>
            <v-btn
              small
              text
              color="primary"
              class="my-2"
              @click="selectedItems = []"
              >Auswahl zurücksetzen</v-btn
            >
            <hr />
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="primary"
                text
                @click="appendRisks"
                data-cy="addAreas"
                disabled
                >Einfügen nach...</v-btn
              >
            </div>
            <div class="d-flex justify-center mt-4">- oder -</div>
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="primary"
                text
                @click="appendRisks"
                data-cy="addAreas"
                >Am Ende der Liste einfügen</v-btn
              >
            </div></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="dialog = false">{{
              $t("general.back")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as riskAssessmentsContentDe from "../../api/content/riskAssignmentsContent-de.json";
import * as riskAssessmentsContentEn from "../../api/content/riskAssignmentsContent-en.json";

export default {
  props: ["value"],
  components: {},
  data: () => ({
    dialog: false,
    presetItems: null,
    selectedItems: [],
    allParentNodes: true,
  }),
  created() {
    this.presetItems = localStorage.getItem("locale") === "en" ? riskAssessmentsContentEn.default : riskAssessmentsContentDe.default;
  },
  methods: {
    appendRisks() {
      //set selection
      this.selectedItems.forEach((element) => {
        this.value.risks.push(element);
      });
      //clean up and close dialog
      this.selectedItems = [];
      this.dialog = false;
    },
  },
};
</script>
