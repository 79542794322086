<template>
  <div>
    <orSeparator style="max-width: 160px" class="mx-auto" />
    <div class="d-flex justify-center mt-4">
      <v-btn
        color="primary"
        outlined
        @click.stop="addArea(value.risks.length + 1)"
      >
        {{ $t("riskAssessment.addRisk") }}
      </v-btn>
    </div>
    <v-row class="mb-4 mt-2">
      <div class="subtitle-1">Kategorien</div>
    </v-row>
    <v-expansion-panels v-model="openRisks" multiple>
      <v-expansion-panel v-for="(risk, i) in value.risks" :key="i">
        <v-expansion-panel-header>
          {{ risk.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            class="mb-6"
            v-model="risk.title"
            label="Bezeichnung (Gefahr)"
            data-cy="riskName"
            dense
            hide-details
            :rules="config.rules"
          ></v-text-field>
          <div class="caption">Schutzmaßnahme</div>
          <div :class="{ 'validation-border': !risk.action }">
            <quill-editor
              class="largeTextField mb-6"
              data-cy="action"
              v-model="risk.action"
              :options="editorOptionAvail"
            ></quill-editor>
          </div>
          <div v-if="!risk.action" class="required-error error--text">
            {{ $t("general.fieldRequired") }}
          </div>
          <div class="caption">Prüfung</div>
          <div :class="{ 'validation-border': !risk.measurement }">
            <quill-editor
              class="largeTextField mb-6"
              data-cy="measurement"
              v-model="risk.measurement"
              :options="editorOptionAvail"
            ></quill-editor>
          </div>
          <div v-if="!risk.measurement" class="required-error error--text">
            {{ $t("general.fieldRequired") }}
          </div>
          <v-text-field
            class="mb-6 mt-6"
            v-model="risk.legal"
            label="Rechtsnorm"
            data-cy="legal"
            dense
            hide-details
          ></v-text-field>
          <v-switch
            v-model="risk.relevantForPregnant"
            label="Relevant für Schwangere"
          ></v-switch>
          <div class="caption">Maßnahmen für Schwangere</div>
          <quill-editor
            class="largeTextField mb-6"
            data-cy="actionForPregnant"
            v-model="risk.actionForPregnant"
            :options="editorOptionAvail"
          ></quill-editor>
          <v-autocomplete
            v-model="risk.responsible"
            :items="userDataMap"
            :loading="getAllUsersStatus"
            hide-no-data
            item-text="Description"
            item-value="id"
            label="Verantwortlicher"
            data-cy="users"
            dense
            :rules="config.rules"
          ></v-autocomplete>
          <v-row>
            <v-col>
              <riskDialog
                v-model="value.risks[i].riskClassification"
                :dialogState="value.risks[i].dialogs.classificationDialog"
              />
              <v-btn
                @click="value.risks[i].dialogs.classificationDialog.open = true"
                block
                outlined
                :color="riskColor(value.risks[i].riskClassification)"
              >
                Risiko: {{ value.risks[i].riskClassification }}
              </v-btn>
            </v-col>
            <v-col>
              <riskDialog
                v-model="value.risks[i].riskClassificationAfterAction"
                :dialogState="value.risks[i].dialogs.afterActionDialog"
              />
              <v-btn
                @click="value.risks[i].dialogs.afterActionDialog.open = true"
                block
                outlined
                :color="riskColor(value.risks[i].riskClassificationAfterAction)"
              >
                Risiko nach Maßnahme:
                {{ value.risks[i].riskClassificationAfterAction }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="risk.involvesThirdParties"
                label="Betrifft Dritte"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                v-model="risk.requiresSpecialTraining"
                label="Benötigt spezielle Schulung"
              ></v-switch>
            </v-col>
          </v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                class="mr-2 mt-3"
                v-on="on"
                @click.stop="addArea(i)"
                data-cy="add"
              >
                add
              </v-icon>
            </template>
            <span>Gefahr hinzufügen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                class="mr-2 mt-3"
                v-on="on"
                @click.stop="removeArea(i)"
                data-cy="delete"
              >
                delete
              </v-icon>
            </template>
            <span>Gefahr löschen</span>
          </v-tooltip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import riskDialog from "./riskNohlDialog.vue";
import OrSeparator from "@/components/layout/orSeperator.vue";

export default {
  name: "risk-assessment-risk",
  props: ["value", "config"],
  components: { OrSeparator, riskDialog },
  data: () => ({
    openRisks: [0],
    editorOptionAvail: {
      placeholder: "Inhalt des Tickets hier einfügen...",
      modules: {
        toolbar: true,
      },
    },
  }),
  mounted() {
    this.$store.dispatch("getUsers");
  },
  computed: {
    ...mapGetters(["getAllUsers", "getAllUsersStatus"]),
    userDataMap() {
      return this.getAllUsers.map((user) => ({
        Description: `${user.name} - ${user.short}`,
        id: user._id,
        ...user,
      }));
    },
  },
  methods: {
    addArea(index) {
      this.value.risks.splice(index + 1, 0, {
        title: "",
        action: "",
        measurement: "",
        legal: "",
        relevantForPregnant: false,
        actionForPregnant: "",
        responsible: "",
        riskClassification: 1,
        riskClassificationAfterAction: 0,
        involvesThirdParties: false,
        requiresSpecialTraining: false,
        dialogs: {
          classificationDialog: { open: false },
          afterActionDialog: { open: false },
        },
      });
      this.openRisks.push(index + 1);
    },
    removeArea(index) {
      this.value.risks.splice(index, 1);
    },
    riskColor(risk) {
      if (risk < 3) return "success";
      if (risk < 5) return "warning";
      return "error";
    },
  },
};
</script>

<style scoped>
.largeTextField > .ql-container {
  min-height: 120px !important;
  font-size: 16px !important;
}

.largeTextField > .ql-toolbar {
  font-size: 16px !important;
}
.validation-border {
  border: 1px solid #b71c1c;
}
.required-error {
  font-size: 12px;
  line-height: 16px;
}
</style>