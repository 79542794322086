<template>
  <!--translate-->
  <div>
    <v-card class="mx-auto my-4 mb-8" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row class="mb-3">
          <h3>{{ $t("settings.emailSettings.title") }}</h3>
          <div class="flex-grow-1"></div>
        </v-row>
      </v-container>
      <v-form class="pa-4 pt-0" ref="form" lazy-validation>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(text, textIndex) in texts"
            v-bind:key="textIndex"
            cols="12"
            sm="12"
            md="12"
          >
            <v-expansion-panel-header>
              <h4>{{ text.title }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(item, itemIndex) of text.items" :key="itemIndex">
                <v-divider v-if="itemIndex !== 0" class="my-4"></v-divider>
                <div>
                  <div v-if="item.label" class="text-h5">
                    {{ item.label }}
                  </div>
                  <v-row>
                    <template v-if="item.placeholders?.length">
                      <v-col cols="12" md="3" class="order-md-3 mt-4 mt-md-0">
                        <div class="text-h6">
                          {{ $t("settings.emailSettings.variables") }}
                        </div>
                        <div
                          class="placeholder-container d-flex flex-row flex-md-column"
                        >
                          <div
                            v-for="(placeholder, index) of item.placeholders"
                            :key="index"
                            class="placeholder-item"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div
                                  v-on="on"
                                  @click.prevent="
                                    placeholderClick(
                                      item.type,
                                      textIndex,
                                      itemIndex,
                                      `{{${placeholder.key}}}`
                                    )
                                  "
                                >
                                  <span>&#123;</span><span>&#123;</span
                                  >{{ placeholder.key }}<span>&#125;</span
                                  ><span>&#125;</span>
                                </div>
                              </template>
                              <span>{{ placeholder.label }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="auto" class="order-2 d-none d-md-block">
                        <v-divider vertical></v-divider> </v-col
                    ></template>
                    <v-col cols="12" md="8" class="order-md-1">
                      <v-text-field
                        v-if="item.type === 'text'"
                        v-model="item.value"
                        :label="item.label"
                      ></v-text-field>
                      <quill-editor
                        v-else-if="item.type === 'quill'"
                        :ref="`quillEditor${textIndex}.${itemIndex}`"
                        class="largeTextField mb-6 ma-2"
                        data-cy="action"
                        v-model="item.value"
                        :options="editorOptionAvail"
                      ></quill-editor>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveEmailSettings">{{
          $t("general.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    texts: [],
    editorOptionAvail: {
      placeholder: "Individuellen Email-Text hier einfügen...",
      modules: {
        toolbar: true,
      },
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getSettingData", "emailTexts").then((item) => {
        this.texts = [
          {
            title:
              "Unterweisungsbenachrichtigungen an die zugewiesenen Mitarbeiter.",
            items: [
              {
                type: "text",
                key: "new_instruction_assignment_subject",
                label: this.$t("settings.emailSettings.subject"),
                value: item.value.new_instruction_assignment_subject,
                placeholders: [
                  {
                    key: "employeeName",
                    label: "Vorname des Adressaten",
                  },
                  {
                    key: "instructionName",
                    label: "Name der Unterweisung",
                  },
                ],
              },
              {
                type: "quill",
                key: "new_instruction_assignment",
                label: this.$t("settings.emailSettings.message"),
                value: item.value.new_instruction_assignment,
                placeholders: [
                  {
                    key: "employeeName",
                    label: "Vorname des Adressaten",
                  },
                  {
                    key: "instructionName",
                    label: "Name der Unterweisung",
                  },
                  {
                    key: "link",
                    label: "Link um die Unterweisung durchzuführen",
                  },
                ],
              },
            ],
          },
          {
            title:
              "Untersuchungsbenachrichtigungen an die zugewiesenen Mitarbeiter.",
            items: [
              {
                type: "text",
                key: "new_examination_subject",
                label: this.$t("settings.emailSettings.subject"),
                value: item.value.new_examination_subject,
                placeholders: [
                  {
                    key: "employeeName",
                    label: "Vorname des Adressaten",
                  },
                  {
                    key: "examinationName",
                    label: "Name der Untersuchung",
                  },
                ],
              },
              {
                type: "quill",
                label: this.$t("settings.emailSettings.obligatoryExaminations"),
                key: "new_examination_obligatory",
                value: item.value.new_examination_obligatory,
                placeholders: [
                  {
                    key: "employeeName",
                    label: "Vorname des Adressaten",
                  },
                  {
                    key: "examinationName",
                    label: "Name der Untersuchung",
                  },
                  {
                    key: "link",
                    label: "Link um die Untersuchung durchzuführen",
                  },
                ],
              },
              {
                type: "quill",
                label: this.$t("settings.emailSettings.optionalExaminations"),
                key: "new_examination_optional",
                value: item.value.new_examination_optional,
                placeholders: [
                  {
                    key: "employeeName",
                    label: "Vorname des Adressaten",
                  },
                  {
                    key: "examinationName",
                    label: "Name der Untersuchung",
                  },
                ],
              },
            ],
          },
        ];
      });
    },
    placeholderClick(type, textIndex, itemIndex, insertText) {
      if (type === "text") {
        this.copyText(insertText);
      } else if (type === "quill") {
        this.insertTextAtCursor(textIndex, itemIndex, insertText);
      }
    },
    async copyText(text) {
      if (
        navigator.clipboard &&
        typeof navigator.clipboard.writeText === "function"
      ) {
        await navigator.clipboard.writeText(text);
        this.$store.dispatch("triggerSnackbar", this.$t("general.copied"));
      } else {
        console.warn(
          "Clipboard API wird von diesem Browser nicht unterstützt. Fallback wird verwendet."
        );
        this.fallbackCopyToClipboard(text);
      }
    },
    copyToClipboardFallback(text) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed"; // Verhindert das Scrollen
      textarea.style.opacity = "0"; // Unsichtbar machen
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        console.log("Text wurde kopiert!");
      } catch (err) {
        console.error("Fehler beim Kopieren in die Zwischenablage:", err);
      }
      document.body.removeChild(textarea);
    },

    insertTextAtCursor(textIndex, itemIndex, insertText) {
      const editorRef = this.$refs[`quillEditor${textIndex}.${itemIndex}`][0];
      const editor = editorRef.quill;

      // Explicitly focus the editor
      editor.focus();

      // Delay getting the selection slightly to ensure focus takes effect
      this.$nextTick(() => {
        const cursorPosition = editor.getSelection()?.index || 0;
        editor.insertText(cursorPosition, insertText);
        editor.setSelection(cursorPosition + insertText.length);
      });
    },
    saveEmailSettings() {
      //set edited data
      //new_instruction_assignment
      const settings = this.texts.reduce(
        (acc, curr) => ({
          ...acc,
          ...curr.items.reduce(
            (acc2, curr2) => ({ ...acc2, [curr2.key]: curr2.value ?? "" }),
            {}
          ),
        }),
        {}
      );
      this.$store
        .dispatch("setSettingData", {
          id: "emailTexts",
          value: settings,
        })
        .finally(() => this.initialize());
    },
  },
};
</script>
<style scoped>
.largeTextField > .ql-container {
  min-height: 120px !important;
  font-size: 16px !important;
}

.largeTextField > .ql-toolbar {
  font-size: 16px !important;
}
.required-error {
  font-size: 12px;
  line-height: 16px;
}
.placeholder-container {
  gap: 8px;

  .placeholder-item {
    width: fit-content;
    cursor: pointer;
    background: rgb(230, 230, 230);
    padding: 2px 4px;
    border-radius: 10px;
  }
}
</style>
